html, body {
  width: 100%;
  height: 100%;
}

input::-ms-clear, input::-ms-reveal {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
}

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title], abbr[data-original-title] {
  -webkit-text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1em;
}

input[type="text"], input[type="password"], input[type="number"], textarea {
  -webkit-appearance: none;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

a, area, button, [role="button"], input:not([type="range"]), label, select, summary, textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  text-align: left;
  caption-side: bottom;
  padding-top: .75em;
  padding-bottom: .3em;
}

input, button, select, optgroup, textarea {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
  margin-bottom: .5em;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  background-color: #feffe6;
  padding: .2em;
}

.main-layout .logo {
  height: 32px;
  margin: 16px 16px 50px;
}

.main-layout .logo img {
  width: 100% !important;
}

.main-layout .main-layout-content {
  min-height: calc(100vh - 88px);
}

.main-layout .ant-layout-sider-children, .main-layout .ant-layout-sider-children .main-layout-sider-menu {
  background: #363740 !important;
}

.main-layout .ant-layout-sider-children .ant-menu-item {
  height: 56px;
  padding-left: 15px !important;
}

.main-layout .ant-layout-sider-children .ant-menu-item .ant-menu-item-icon {
  font-size: 18px;
}

.main-layout .ant-layout-sider-children .ant-menu-item.ant-menu-item-selected {
  color: #dde2ff;
  background: #3e4049;
  border-radius: unset !important;
}

.main-layout .ant-layout-sider-children .ant-menu-item-divider {
  background: #dde2ff80;
  margin: 28px 0;
}

.top-menu {
  padding: 0 25px;
  font-size: 17px;
}

.top-menu .navigation-item {
  font-weight: 400;
}

.top-menu .navigation-item .icon {
  font-size: 15px;
}

.top-menu .navigation-item .divider, .top-menu .navigation-item .label {
  font-size: 19px;
}

.top-menu .navigation-item a {
  color: #777;
}

.top-menu .navigation-item.main-nav {
  font-size: 17px;
}

.top-menu .navigation-item.main-nav a {
  color: #111 !important;
}

.dashboard-page .ev-timing {
  margin-top: 20px;
  font-size: 17px;
}

.dashboard-page .ctg-wrapper {
  cursor: pointer;
  height: 65px;
  border-left: 10px solid;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
}

.dashboard-page .ctg-wrapper .title {
  height: 20px;
  font-weight: bold;
  overflow: hidden;
  color: #333 !important;
}

.actions-bar .search-wrap {
  margin: 21px 0;
}

.actions-bar .search-wrap .ant-input-affix-wrapper {
  padding: 8px 11px;
}

.actions-bar .filter-actions {
  margin: 21px 0;
  padding: 12px;
}

.actions-bar .filter-actions .filter-wrap {
  color: #c5c7cd;
  cursor: pointer;
}

.actions-bar .filter-actions .filter-wrap .icon {
  margin-right: 10px;
}

.actions-bar .filter-actions .filter-wrap .filter-text {
  color: #4b506d;
}

.actions-bar .right-actions {
  text-align: right;
  margin: 21px 0;
}

.listing-head-actions {
  color: #fff;
  height: 36px;
  background: #9fa2b4;
  border-radius: 5px 5px 0 0;
  padding: 10px 23px;
}

.listing-head-actions .action-icon {
  cursor: pointer;
  margin-right: 23px;
  font-size: 16px;
}

.menu-item-tag-wraper {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
}



.manage-menu-item .tag-check-wrapper {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
}

.manage-menu-item .tag-check-wrapper .tag-img {
  width: 40px;
}





.review-actions {
  font-size: 20px;
}

.review-actions .review-actions-check {
  color: #63954a;
  cursor: pointer;
  opacity: .5;
}

.review-actions .review-actions-close {
  color: #daa520;
  cursor: pointer;
  opacity: .5;
}

.review-actions .active {
  opacity: 1;
}

.review-actions .review-actions-trash {
  color: #ff0019;
  cursor: pointer;
}

.main-btn.outiled {
  background: unset;
  color: #2d66db;
  border-color: #2d66db;
}

.main-btn.outiled:hover {
  color: #4096ff !important;
}

.login-container {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.login-container .container-card {
  width: 30%;
}

.login-container .container-card .logo {
  width: 50%;
  height: auto;
  margin: 0 auto 15px;
  display: block;
}

.login-container .submit-btn {
  width: 100%;
  height: 40px;
  margin-top: 12px;
}

.login-container .label {
  font-weight: 500;
}

.login-container .input {
  height: 40px;
  background: #f6f8fa;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.login-container .input input {
  background: #f6f8fa;
}

.login-container .input .ant-input-prefix {
  color: #9fa2b4;
  margin-right: 6px;
}

.login-container .ant-form-item {
  margin-bottom: 28px !important;
}

@media screen and (max-width: 900px) {
  .login-container .container-card {
    width: 90%;
  }
}

/*# sourceMappingURL=index.ddbc3979.css.map */
