.main-layout {
  .logo {
    height: 32px;
    margin: 16px;
    // background: rgba(255, 255, 255, 0.2);
    margin-bottom: 50px;

    img{
      width: 100% !important;
    }
  }

  .main-layout-content {
    // padding: 24px;
    min-height: calc(100vh - 88px);
  }

  .ant-layout-sider-children {
    background: #363740 !important;
    .main-layout-sider-menu {
      background: #363740 !important;
    }

    .ant-menu-item {
      padding-left: 15px !important;
      height: 56px;
      .ant-menu-item-icon {
        font-size: 18px;
      }
    }
    .ant-menu-item.ant-menu-item-selected {
      background: #3e4049;
      border-radius: unset !important;
      color: #dde2ff;
    }
    .ant-menu-item-divider {
      //   // background: #dfe0eb;
      margin: 28px 0;
      background: rgba(221, 226, 255, 0.5);
    }
  }
}
// UNSELECTED MENU ITEM COLOR color: rgba(221, 226, 255, 0.5);
