.review-actions {
  font-size: 20px;

  .review-actions-check {
    color: #63954a;
    cursor: pointer;
    opacity: 0.5;
  }
  .review-actions-close {
    color: #daa520;
    cursor: pointer;
    opacity: 0.5;
  }
  .active {
    opacity: 1;
  }
  .review-actions-trash {
    color: #ff0019;
    cursor: pointer;
  }
}


// .main-btn {
//   // width: 140px;
//   // min-height: 40px;

//   // background: #2d66db;
//   // border-radius: 8px;
//   // color: #fff;
//   // &:hover {
//   //   color: #fff !important;
//   // }
// }
.main-btn.outiled {
  background: unset;
  border-color: #2d66db;
  color: #2d66db;
  &:hover {
    color: #4096ff !important;
  }
}
