.top-menu {
  padding: 0 25px;
  font-size: 17px;

  .navigation-item {
    font-weight: 400;
    .icon{
      font-size: 15px;
    }
    .divider,
    .label {
      font-size: 19px;
    }
    a {
      color: #777;
    }
    &.main-nav {
      font-size: 17px;
      a {
        color: #111 !important;
      }
    }
  }
}
