.listing-head-actions {
  background: #9fa2b4;
  color: #fff;
  height: 36px;
  border-radius: 5px 5px 0px 0px;
  padding: 10px 23px;

  .action-icon {
    cursor: pointer;
    font-size: 16px;
    margin-right: 23px;
  }
}
.menu-item-tag-wraper {
  margin-bottom: 5;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}
