.actions-bar {
  // margin: 21px 0;
  // height: 40px;
  .search-wrap {
    margin: 21px 0;
    .ant-input-affix-wrapper {
      padding: 8px 11px;
    }
  }
  
  .filter-actions {
    padding: 12px;
    margin: 21px 0;

    .filter-wrap {
      color: #c5c7cd;
      cursor: pointer;
      // margin-left: 30px;
      // display: inline-block;
      // padding-top: 8px;

      .icon {
        margin-right: 10px;
      }
      .filter-text {
        color: #4b506d;
      }
    }
  }

  .right-actions {
    margin: 21px 0;
    text-align: right;
  }
}
