.manage-menu-item {
  .tag-check-wrapper {
    margin-bottom: 5;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;

    .tag-img {
      width: 40px;
    }
  }
}
