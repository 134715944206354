.dashboard-page {
  .ev-timing {
    // font-weight: bold;
    font-size: 17px;
    margin-top: 20px;
  }

  .ctg-wrapper {
    padding: 10px; //15px 20px;
    cursor: pointer;
    border-left: 10px solid;
    border-radius: 10px;
    height: 65px;
    overflow: hidden;
    // width: 100%;
    // height: 100px !important;

    .title {
      font-weight: bold;
      color: #333 !important;
      height: 20px;
      overflow: hidden;
      // margin-bottom: 5px;
    }
  }
}
