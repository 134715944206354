.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .container-card {
    width: 30%;

    .logo {
      display: block;
      width: 50%;
      height: auto;
      margin: 0 auto;
      margin-bottom: 15px;
    }
  }

  .submit-btn {
    width: 100%;
    height: 40px;
    margin-top: 12px;
  }


  .label {
    font-weight: 500;
  }

  .input {
    height: 40px;
    background: #f6f8fa;
    border: 1px solid #ebebeb;
    border-radius: 4px;

    input {
      background: #f6f8fa;
    }

    .ant-input-prefix {
      color: #9fa2b4;
      margin-right: 6px;
    }
  }

  .ant-form-item {
    margin-bottom: 28px !important;
  }
}

@media screen and (max-width: 900px) {
  .login-container {
    .container-card {
      width: 90%;
    }
  }
}